import React from 'react'
import Layout from '../components/layout'
import '../styles/CallUs.css'
import {Helmet} from 'react-helmet'

import location from '../images/location2.png'
import whatsapp from '../images/whatsapp2.png'
import mail from '../images/mail2.png'
import phone from '../images/phone2.png'
import { Link } from 'gatsby'

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'
import favicon from '../images/favicon.ico'

const CallUs = () => {
  return (
    <div className='bg-white'>
      <Layout>
      <Helmet title="Contact Us | Ramasko | راماسكو"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
         <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
          <div className='flex justify-center'>
              <div className='grid grid-cols-1 sm:grid-cols-3 xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6 lg:gap-10 md:gap-24 '>
                  <div className='column1Call bg-white flex items-center justify-end'>
                    <div>
                      <h1 className='titleCall text-center sm:text-right font-Almarai' dir='rtl'>اتصل بنا</h1>
                      
                      <div className='addressDiv flex items-start justify-end pb-2'>
                        <Link to='https://www.google.com/maps/place/Ramasko+Packing+System/@21.4964126,39.2114093,17z/data=!3m1!4b1!4m5!3m4!1s0x15c3cf93845872cd:0xe3dd6e34fe3b9168!8m2!3d21.4964076!4d39.213598'><h1 className='addressTextCall text-right font-Almarai' dir='rtl'>  عنواننا<br/>
 جده – الهنداويه –  شارع الذهب <br/>مقابل بنك الراجحي</h1></Link>
                        <img className='lg:w-4 md:w-3 w-3' src={location} alt='icons' />
                      </div>
                      <div className='addressDiv flex items-center justify-end pb-2'>
                        <h1 className='addressTextCall text-right'>0096605324447438</h1>
                        <img className='lg:w-5 md:w-4 w-4' src={whatsapp} alt='icons' />
                      </div>
                      <div className='addressDiv flex items-center justify-end pb-2'>
                        <h1 className='addressTextCall text-right'>ramasko33@gmail.com</h1>
                        <img className='lg:w-5 md:w-4 w-4' src={mail} alt='icons' />
                      </div>
                      <div className='addressDiv flex items-start justify-end pb-2'>
                        <h1 className='addressTextCall text-right'>  +9660532447438<br/>+966551161149</h1>
                        <img className='lg:w-5 md:w-4 w-4' src={phone} alt='icons' />
                      </div>

                    </div>
                  </div>

                  <div className='column2Call col-span-2 flex justify-start '>
                    <div>
                      <h1 className='titleGetCall font-Almarai text-gray-700' dir='rtl'> ابقى على تواصل </h1>
                      <h1 className='subTitleGetCall font-Almarai text-gray-700' dir='rtl'>هاندأ طخلا بلع انتلسارم يف ددرتت ال!</h1>

                      <form action="https://getform.io/f/2e5047bc-85e7-49bb-adc8-f4a2cfaf7807" method="POST">
                        <div className="lg:my-4 md:my-3 my-3">
                          <input className="textFieldCall shadow appearance-none text-sm sm:text-base  xl:py-4 lg:py-3 md:py-3 py-2      xl:px-6 lg:px-5 md:px-3 px-2 leading-tight focus:outline-none focus:shadow-outline font-Almarai" name='Name' id="name" type="text" placeholder="اسمك" dir='rtl' />
                        </div>
                        <div className="md:mb-1 mb-0">
                          <input className="textFieldCall shadow appearance-none text-sm sm:text-base  xl:py-4 lg:py-3 md:py-3 py-2      xl:px-6 lg:px-5 md:px-3 px-2 mb-3 leading-tight focus:outline-none focus:shadow-outline font-Almarai" name='Email' dir='rtl' id="email" type="email" placeholder="بريدك الالكتروني" />
                        </div>
                        <div className="xl:mb-4 lg:mb-2 md:mb-1 mb-1">
                          <textarea className="textAreaCall shadow appearance-none text-sm sm:text-base  xl:py-4 lg:py-3 md:py-3 py-2       xl:px-6 lg:px-5 md:px-3 px-2 mb-3 leading-tight focus:outline-none focus:shadow-outline font-Almarai" name='Message' dir='rtl' id="message" type="text" placeholder="اكتب مساجك هنا...." />
                        </div>
                        <button type="submit" className= "first-letter: text-sm lg:text-base font-bold text-white  rounded-lg xl:px-10 lg:px-8 px-8 xl:py-3 lg:py-2 py-2 text-center bg-primary hover:font-bold  underline-offset-2 decoration-2 font-Almarai">
                        إرسال
                        </button>
                        </form>
                    </div>

                    
                      
                          
                  </div>
              </div>

          </div>
          <FloatingWhatsApp
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />
          
      </Layout>
    </div>
  )
}

export default CallUs
